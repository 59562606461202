import React from "react";

import Layout from "../components/Layout/Layout";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = (): React.ReactNode => {
  return (
    browser && (
      <Layout>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  );
};

export default NotFoundPage;
